<template>
  <v-container class="page-container" fill-height fluid grid-list-xl
  >
    <v-layout wrap id="configuration">
      <!-- Header title -->
      <h1 class="page-title text-xs-left mb-3 w-100">{{ pageTitle }}</h1>

      <!-- Add goal button -->
      <div class="flex-display flex-row flex-justify-end mb-3 w-100">
        <button class="btn add-new" @click="showAddCollectionDialog('add',null)"><i
            class="v-icon mdi mdi-plus"></i> {{ addButtonText }}
        </button>
      </div>

      <!-- Goals list -->
      <div class="w-100" v-if="isReady">
        <draggable :list="collections" @end="onEnd">
          <transition-group>
            <div class="item-container"
                 v-for="(collection, index) in collections"
                 :key="collection.id"
            >
              <span class="m-l-minus-25 pr-4">{{ index + 1 }}</span>
              <i class="fa fa-arrows cursor-move position-absolute pr-4"></i>
              <p class="item-text w-100 position-relative pl-4">{{ collection.name }}</p>
              <div class="item-actions">
                <button class="btn-edit" @click="showAddCollectionDialog('edit', index)">Edit</button>
                <button class="btn-delete ml-2" @click="deleteCollection(collection)">Delete</button>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>

      <!-- Add goal dialog -->
      <add-collection v-if="addCollectionDialog"
                      :visible="addCollectionDialog"
                      :actionType="actionType"
                      :collections="collections"
                      :skills="skills"
                      :value="selectedCollection"
                      v-on:submitCollection="addCollection"
                      v-on:hide="hideAddCollectionDialog"
      >
      </add-collection>
    </v-layout>

  </v-container>
</template>

<script>
import AddCollection from "../components/configuration/AddCollection";
import draggable from 'vuedraggable'
import arrayHelper from "@/helpers/arrayHelper";
import routeHelper from "@/helpers/app/routeHelper";

export default {
  'name': 'SelfCareCollection',
  components: {
    AddCollection,
    draggable
  },
  data: () => ({
    pageTitle: 'Immediate relief collections',
    addButtonText: 'New Collection',
    isReady: true,
    addCollectionDialog: false,
    deleteCollectionDialog: false,
    actionType: '',
    collections: [],
    skills: [],
    selectedIndex: null,
    selectedCollection: {
      name: '',
      status: '',
      keywords: []
    },
  }),
  created() {
    this.getCollection();
  },
  methods: {
    getCollection() {
      let loader = this.$loading.show();
      this.$http.get(this.url + 'self_care/collection').then(response => {
        loader.hide();
        this.collections = response.data.collections;
        this.skills = response.data.skills;
      }).catch(error => {
        loader.hide();
        this.$toastr('error', error.response.data.message, 'Error');
      })
    },

    showAddCollectionDialog(action, index) {
      this.addCollectionDialog = true;
      this.actionType = action;
      this.selectedIndex = index;
      if (this.selectedIndex === null) {
        this.resetSelectedCollection();
      } else {
        this.selectedCollection = this.collections[index];
      }
    },
    deleteCollection(collection) {
      let confirmDelete = confirm("Are you sure to delete this collection?");
      if (confirmDelete !== true) {
        return;
      }
      let loader = this.$loading.show();
      this.$http.delete(this.url + 'self_care/collection', {
        data: {
          collection_id: collection.id
        }
      }).then(response => {
        loader.hide();
        this.getCollection();
        this.$toastr('success', response.data.message, '');
      }).catch(error => {
        loader.hide();
        this.$toastr('error', error.response.data.message, '');
      });
    },
    hideAddCollectionDialog() {
      this.resetSelectedCollection();
      this.addCollectionDialog = false;
    },
    resetSelectedCollection() {
      this.selectedIndex = null;
      this.selectedCollection = {
        name: '',
        status: '',
        keywords: []
      }
    },
    onEnd(evt) {
      this.collections.map((collection, index) => {
        collection.order = index + 1;
      })
      this.$http.put(this.url + routeHelper.sortCollectionApi, {
        sorted_collection: this.collections
      }).then(response => {
        this.$toastr('success', response.data.message, '');
      })
    },
    // Update the view after collection submission
    addCollection(collection) {
      this.selectedIndex === null ? this.collections.push(collection) : this.collections[this.selectedIndex] = collection;
      this.hideAddCollectionDialog();
    }
  }
}
</script>
<style scoped>
.m-l-minus-25 {
  margin-left: -25px;
}

</style>
